import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .training-targets-editor-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .training-targets-editor-header-col  {
                display: flex;
                align-items: center;


                &.col-left {
                    justify-content: flex-start;

                    .training-targets-editor-header-headline {
                        font-size: 1.5em;
                        color: ${variables.dpc_fontDark};
                        font-weight: 400;
                        margin-right: 2em;
                    }

                    .is-primary-target {
                        font-size: 1.5em;
                        color: ${variables.dpc_fontDark};
                        font-weight: 400;
                        margin-right: 2em;
                        min-width: 7em;
                    }

                    .is-finished-target {
                        font-size: 1.5em;
                        color: ${variables.dpc_fontDark};
                        font-weight: 400;
                        margin-right: 2em;
                    }

                    .training-target-end-button {
                        margin-left: 2em;
                    }
                }
                
                &.col-right {
                    justify-content: flex-end;
                }


                .training-targets-editor-header-datepicker {
                    margin-left: 0em;
                }
            }
        }

        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    width: 100% !important;
                    padding-right: 0 !important;

                    .form-elements {
                        .box {
                            &.size-undefined {
                                width: 32%;
                            }

                            .input-internal-wrapper {
                                font-size: 1.5em;
                            }

                            .textarea {
                                textarea {
                                    font-size: 1.5em;
                                }
                            }

                            .form-error-message-component {
                                text-align: left;
                                color: red;
                            }
                        }
                    }
                }
                .data-wrapper {
                    display: none;
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .training-targets-editor-header {
                flex-direction: column;
                align-content: flex-start;

                .training-targets-editor-header-col, .col-right {
                    margin-top: 1.5em;
                }
            }
        }

        @media (max-width: ${variables.desktopM}) {
            .admin-element-editor {
                .editor-wrapper {
                    .forms-wrapper {
                        width: 100% !important;
                        padding-right: 0 !important;

                        .form-elements {
                            .box {
                                &.size-undefined {
                                    width: 49%;
                                }
                            }
                        }
                    }
                }

                .header-wrapper {
                    .training-targets-editor-header {
                        .col-right {
                            width: 20%;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .admin-element-editor {
                .editor-wrapper {
                    .forms-wrapper {
                        width: 100% !important;
                        padding-right: 0 !important;

                        .form-elements {
                            .box {
                                &.size-undefined {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .header-wrapper {
                    .training-targets-editor-header {
                        flex-direction: column;

                        .col-left {
                            flex-direction: column;
                            width: 100%;

                            .is-primary-target {
                                margin-right: 0;
                            }

                            .component-button {
                                margin-top: 1.5em;
                                width: 100%;
                                
                                .training-target-primary-button {
                                    width: 100%;
                                }

                                .training-target-end-button {
                                    margin-left: 0;
                                    width: 100%;
                                }
                            }
                        }

                        .col-right {
                            width: 100%;
                        }
                    }
                }
            }
        }        
    `;

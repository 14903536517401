import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE, TRAINER_TRAINING_TARGETS_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/trainer/trainingTargets/Editor';
import { TAB_TRAINING_TARGETS } from 'Components/pages/trainer/UsersManage/component';

export default class TrainerTrainingTargetsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;
        const queryObject = parseQueryToObject(location.search);

        return (
            <StyledComponent
                className="trainer-training-targets-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: queryObject.userId }, { tab: TAB_TRAINING_TARGETS }),
                        label: 'Cele treningowe',
                    }, {
                        to: withVariables(TRAINER_TRAINING_TARGETS_CREATE.path, {}, { userId: queryObject.userId }),
                        label: 'Dodaj nowy cel treningowy',
                    }]}
                >
                    <ViewHeader
                        title="Nowy cel treningowy"
                    />
                    <Editor
                        location={location}
                        history={history}
                        predefinedState={{
                            userId: queryObject.userId,
                        }}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADEPT_DATA } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/adept/trainingTargets/Editor';

export default class AdeptTrainingTargetsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="adept-training-targets-create"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Nowy cel treningowy"
                        controls={[{
                            visible: true,
                            key: 'return',
                            label: 'Powrót do celów treningowych',
                            onClick: () => history.push(
                                withVariables(ADEPT_DATA.path, {}, { tab: 'trainingTargets' }),
                            ),
                            style: 'gradient',
                            layout: 'wide',
                        }]}
                        tabs={[{
                            key: 'trainingGoal',
                            label: 'Cel treningowy',
                            children: (
                                <Editor
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
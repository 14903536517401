import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .training-targets-editor-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .training-targets-editor-header-col  {
                    display: flex;
                    align-items: center;

                    &.col-left {
                        justify-content: flex-start;

                        .training-targets-editor-header-headline {
                            font-size: 1.5em;
                            color: ${variables.dpc_fontDark};
                            font-weight: 400;
                            margin-right: 2em;
                        }

                        .switch {
                            margin-bottom: 0;

                            .input-external-wrapper {
                                margin-bottom: 0;

                                .input-label{
                                    color: ${variables.dpc_fontDark};
                                    margin-right: 1em;
                                }
                            }
                        }
                    }
                    
                    &.col-right {
                        justify-content: flex-end;
                    }


                    .training-targets-editor-header-datepicker {
                        margin-left: 0em;
                    }
                }
            }

            .admin-element-editor {
                .editor-wrapper {
                    .forms-wrapper {
                        width: 100% !important;
                        padding-right: 0 !important;
                    }
                    .data-wrapper {
                        display: none;
                    }
                }
            }
    `;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

import { API_RESOURCE_TRAINING_TARGET, API_RESOURCE_TRAINING_TARGET_CATEGORIES } from 'Consts/apiResources';
import { ADEPT_TRAINING_TARGETS_MANAGE, ADEPT_DATA } from 'Consts/routes';

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { toApiFormat as dateToApiFormat, getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import Button from 'Components/layout/Button';
import ChangePrimaryTarget from 'Components/adept/modals/ChangePrimaryTarget';

export default class AdeptTrainingTargetsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
            listTrainingTargetCategories: PropTypes.func.isRequired,
            finish: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {
            ...this.props.predefinedState, //eslint-disable-line react/destructuring-assignment
        },
        blockPrimary: false,
        changePrimaryTarget: false,
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        categoryId: data.category
            ? { value: data.category.id, label: data.category.name }
            : null,
        startAt: data.startAt && moment(data.startAt).toDate(),
        estimatedTo: data.estimatedTo && moment(data.estimatedTo).toDate(),
        finishAt: data.finishAt && moment(data.finishAt).toDate(),
    })

    onSubmit = formState => {
        const { data } = this.props;
    
        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...formState,
            categoryId: fromSelectObject(formState.categoryId),
            startAt: dateToApiFormat(formState.startAt, 'datetime', true),
            estimatedTo: dateToApiFormat(formState.estimatedTo, 'datetime', true),
            finishAt: dateToApiFormat(formState.finishAt, 'datetime', true),
            primary: formState.primary || false,
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADEPT_TRAINING_TARGETS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_TRAINING_TARGET].id }
                    )
                );
            });
    }
    
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            categoryId: fromSelectObject(formState.categoryId),
            startAt: dateToApiFormat(formState.startAt, 'datetime', true),
            estimatedTo: dateToApiFormat(formState.estimatedTo, 'datetime', true),
            finishAt: dateToApiFormat(formState.finishAt, 'datetime', true),
            primary: formState.primary || false,
        });
    }

    onFinish = () => {
        const { actions, data, history } = this.props;

        actions.finish({ id: data.id }) 
            .then(response => {
                history.push(
                    withVariables(ADEPT_DATA.path, {}, { tab: 'trainingTargets' }),
                );
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[AdeptTrainingTargetsEditor] Failed to finish', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState, changePrimaryTarget } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-training-targets-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    layout="box"
                    styleVersion={2}
                    header={(
                        <div className="training-targets-editor-header">
                            <div className="training-targets-editor-header-col col-left">
                                <div className="is-primary-target">
                                    Cel główny: {formState.primary ? 'Tak' : 'Nie'}
                                </div>
                                { data && data.finishAt && (
                                    <div className="is-finished-target">
                                        Zakończony dnia: {getFormattedDate(data.finishAt)}
                                    </div>
                                )}
                                { data && !data.finishAt && (
                                    <Button
                                        className="training-target-primary-button"
                                        style='hollow'
                                        onClick={() => this.setState({ changePrimaryTarget: true })}
                                    >
                                        Zmień
                                    </Button>
                                )}
                                { data && !data.finishAt && (
                                    <Button
                                        className="training-target-end-button"
                                        style='hollow'
                                        onClick={() => actions.finish({ id: data.id })}
                                    >
                                        Zakończ
                                    </Button>
                                )}
                            </div>
                            <div className="training-targets-editor-header-col col-right">
                                <Button
                                    className="measurements-editor-header-button"
                                    onClick={() => this.onSubmit}
                                    type='submit'
                                    form='editor'
                                    style='hollow'
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </div>
                    )}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        submitButtonStyle: 'hollow',
                        data: formState,
                        submitVisible: false,
                        layout: 'box',
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        styleVersion: 'transparentLight',
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa celu',
                            inputProps: {
                                style: 'transparentV2',
                                styleVersion: 2,
                            },
                        }, {
                            type: 'select',
                            name: 'categoryId',
                            label: 'Kategoria',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/category.svg'),
                            },
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listTrainingTargetCategories({
                                    search: query,
                                    enabled: true,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_TRAINING_TARGET_CATEGORIES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                }),
                                style: 'transparentV2',
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'startAt',
                            label: 'Od kiedy zaczynasz realizować cel',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/calendar.svg'),
                            },
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                                border: 'light', 
                                styleVersion: 2,
                            },
                        }, {
                            type: 'datePicker',
                            name: 'estimatedTo',
                            label: 'Kiedy chcesz osiągnąć cel',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/complete_workout.svg'),
                            },
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                                border: 'light',
                                styleVersion: 2, 
                            },
                        }, {
                            isVisible: Boolean(data),
                            type: 'textarea',
                            name: 'feedback',
                            label: 'Feedback adepta',
                            size: 'fullWidth',
                            inputProps: {
                                border: 'light', 
                                styleVersion: 2,
                            },
                        }, {
                            type: 'textarea',
                            name: 'strategy',
                            label: 'Strategia dotarcia do celu',
                            size: 'fullWidth',
                            inputProps: {
                                disabled: true,  
                                styleVersion: 2,
                                border: 'light-2', 
                                placeholder: 'To pole uzupełnia trener',
                            },
                        }, {
                            isVisible: Boolean(data?.finishAt),
                            name: 'finishAt',
                            type: 'datePicker',
                            label: 'Data osiągnięcia celu',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/calendar.svg'),
                            },
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                                border: 'light', 
                                styleVersion: 2,
                                disabled: true,
                            },
                        }],
                    }]}
                />
                <ChangePrimaryTarget
                    location={location}
                    history={history}
                    isOpen={Boolean(changePrimaryTarget)}
                    onClose={() => this.setState({ changePrimaryTarget: false })}
                    data={{ ...data }}
                />
            </StyledComponent>
        );
    }
}


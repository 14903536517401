import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ADEPT_DATA } from 'Consts/routes';

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdeptChangePrimaryTarget extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            setPrimary: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
    };

    onSubmit = formState => {
        const { actions, data, history } = this.props;

        return actions.setPrimary({ id: data && data.id })
            .then(response => {
                history.push(
                    withVariables(ADEPT_DATA.path, {}, { tab: 'trainingTargets' }),
                );
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[AdeptChangePrimaryTarget] Failed to change training target to primary', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;
        
        if (!data) {
            return (<Spinner />);
        }

        let title;
        if(data.hasPrimary && !data.primary) {
            title = 'Posiadasz już inny cel jako główny, chcesz go zmienić na poboczny a ten jako główny?';
        } else if (!data.primary && !data.hasPrimary) {
            title = 'Nie posiadasz jeszcze celu głównego. Chcesz ustawić ten cel jako główny?';
        } else if (data.primary && data.hasPrimary){
            title = 'Ten cel jest ustawiony jako główny. Chcesz ustawić go cel jako poboczny?';
        }

        return (
            <StyledComponent
                className="adept-change-primary-target"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title,
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            isVisible: false,
                            type: 'switch',
                            name: 'primary',
                            label: 'Główny',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}
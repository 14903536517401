import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

import { API_RESOURCE_TRAINING_TARGET, API_RESOURCE_TRAINING_TARGET_CATEGORIES, API_RESOURCE_USERS } from 'Consts/apiResources';
import { TRAINER_TRAINING_TARGETS_MANAGE } from 'Consts/routes';
import { USER_ROLE_ADEPT } from 'Consts/userRoles';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import Button from 'Components/layout/Button';
import Switch from 'Components/forms/Switch';

export default class TrainerTrainingTargetsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            listTrainingTargetCategories: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {
            ...this.props.predefinedState, //eslint-disable-line react/destructuring-assignment
        },
        products: [],
        blockPrimary: false,
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        userId: data.user
            ? { value: data.user.id, label: getUserFullName(data.user, 'nameSurname').label }
            : null,
        categoryId: data.category
            ? { value: data.category.id, label: data.category.name }
            : null,
        startAt: data.startAt && moment(data.startAt).toDate() || moment().toDate(),
        estimatedTo: data.estimatedTo && moment(data.estimatedTo).toDate(),
        finishAt: data.finishAt && moment(data.finishAt).toDate(),
    })

    formStateToApi = formState => {
        const { predefinedState } = this.props;
        
        return {
            ...formState,
            userId: fromSelectObject(formState.userId),
            categoryId: fromSelectObject(formState.categoryId),
            startAt: dateToApiFormat(formState.startAt),
            estimatedTo: dateToApiFormat(formState.estimatedTo),
            finishAt: dateToApiFormat(formState.finishAt),
            primary: formState.primary || false,
            ...predefinedState,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToApi(formState),
        })
            .then(response => {
                history.push(
                    withVariables(
                        TRAINER_TRAINING_TARGETS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_TRAINING_TARGET].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...this.formStateToApi(formState),
            id: data.id,
        });
    }

    render() {
        const { data, location, history, actions, predefinedState } = this.props;
        const { formState, blockPrimary } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-training-targets-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    layout="box"
                    styleVersion={2} 
                    header={(
                        <div className="training-targets-editor-header">
                            <div className="training-targets-editor-header-col col-left">
                                <h2 className="training-targets-editor-header-headline">
                                    Cel treningowy adepta {getUserFullName(data && data.user, 'nameSurname').label}
                                </h2>
                            </div>
                            <div className="training-targets-editor-header-col col-right">
                                <Button
                                    className="measurements-editor-header-button"
                                    onClick={() => this.onSubmit}
                                    type='submit'
                                    form='editor'
                                    style='gradient'
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </div>
                    )}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        submitVisible: false,
                        data: formState,
                        layout: 'box',
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa celu',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'select',
                            name: 'userId',
                            label: 'Adept',
                            isVisible: Boolean(!data && !predefinedState.userId),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_ADEPT,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element, 'nameSurname').label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'categoryId',
                            label: 'Kategoria',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/category.svg'),
                            },
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listTrainingTargetCategories({
                                    search: query,
                                    enabled: true,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_TRAINING_TARGET_CATEGORIES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                }),
                                style: 'transparentV2',
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'startAt',
                            label: 'Data startu',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/calendar.svg'),
                            },
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                                style: 'version-2',
                                border: 'light-2', 
                            },
                        }, {
                            type: 'datePicker',
                            name: 'estimatedTo',
                            label: 'Przewidywana data zakończenia',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/complete_workout.svg'),
                            },
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                                style: 'version-2',
                                border: 'light-2', 
                            },
                        }, {
                            type: 'datePicker',
                            name: 'finishAt',
                            label: 'Data zakończenia',
                            boxContent: {
                                imageSrc: require('Theme/images/icons/calendar_check_mark.svg'),
                            },
                            isVisible: Boolean(data),
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                },
                                style: 'version-2',
                                border: 'light-2', 
                            },
                        }, {
                            type: 'switch',
                            name: 'primary',
                            label: 'Czy to jest cel główny?',
                            value: formState.primary,
                            inputProps: {
                                style: 'light',
                            },
                        }, {
                            type: 'textarea',
                            name: 'feedback',
                            label: 'Feedback adepta',
                            isVisible: Boolean(data),
                            size: 'fullWidth',
                            inputProps: {
                                disabled: true,
                                styleVersion: 2,
                                border: 'light-2', 
                            },
                        }, {
                            type: 'textarea',
                            name: 'strategy',
                            label: 'Strategia dotarcia do celu',
                            size: 'fullWidth',
                            inputProps: {
                                styleVersion: 2,
                                border: 'light-2', 
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}
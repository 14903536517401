import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    setEnabledStatus,
    finish,
} from 'Redux/modules/adept/trainingTargets/actions';
import { list as listTrainingTargetCategories } from 'Redux/modules/adept/trainingTargetCategories/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            setEnabledStatus: dispatch(setEnabledStatus),
            listTrainingTargetCategories: dispatch(listTrainingTargetCategories),
            finish: dispatch(finish),
        },
    }),
)(Component);
import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 40vw;

    .modal-body-component {
        .adept-change-primary-target {
            width: 100%;

            .admin-element-editor {
                width: 100%;

                .editor-wrapper {
                    .forms-wrapper {
                        min-width: 100%;
                        padding: 0;

                        .form-wrapper { 
                            width: 100%;
                            border: 0;
                            padding-top: 2em;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        width: 100%;
    }

    @media (max-width: ${variables.tabletS}) {
        width: 60vw;
    }

    @media (max-width: ${variables.mobileM}) {
        width: 96vw;
    }    
}
`;

import { connect } from 'react-redux';
import Component from './component';

import {
    setPrimary,
} from 'Redux/modules/adept/trainingTargets/actions';
export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            setPrimary: dispatch(setPrimary),
        },
    }),
)(Component);